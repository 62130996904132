import React from "react";
import "../../pages/admin/AddUser.css";
import { Col, Form, Container, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";


const AssignEdit = ({ selectItem, user, handleClose, getAllProject }) => {


    const adminloginInfo = JSON.parse(localStorage.getItem("adminLoginInfo"));
    const userLoginInfo = JSON.parse(localStorage.getItem("userLoginInfo"));


    if (adminloginInfo) {
        var companyID = adminloginInfo?._id;
        var token = adminloginInfo?.token
    } else if (userLoginInfo) {
        var companyID = userLoginInfo?.companyID;
        var token = userLoginInfo?.token
    }


    // config

    const config = {
        headers: {
            Accept: "application/json",
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`
        },
    };
    

    const [addedUsers, setAddedUsers] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [remaningUsers, setRemaningUsers] = useState([]);
    const [selectdUser, setSelectdUser] = useState("");

    console.log("addedUsers", addedUsers)
    console.log("remaningUsers", remaningUsers)
    console.log("user", user)





    useEffect(() => {
        setAddedUsers(selectItem.Array)
    }, [selectItem])

    useEffect(() => {
        setAllUsers(user)
    }, [user])

    const handleAddUser = (e) => {
        const updatedUser = allUsers.filter(u => u._id === e.target.value);
        setSelectdUser(e.target.value)


        const newData = [{
            _id: updatedUser?.[0]?._id,
            name: updatedUser?.[0]?.name,
            role: updatedUser?.[0]?.role,
            assignedProjects: updatedUser?.[0]?.assignedProjects,
            add: true
        }]
        setAddedUsers(prevData => [...prevData, ...newData]);
        setSelectdUser("")
    }

    const removeHolidayByName = (userID) => {
        const updatedUser = addedUsers.filter(u => u._id !== userID);
        setAddedUsers(updatedUser);

        const addedUser = allUsers.filter(item => item?._id === userID);

        console.log('addedUser', addedUser)

        const newData = [{
            _id: addedUser?.[0]?._id,
            name: addedUser?.[0]?.name,
            role: addedUser?.[0]?.role,
            assignedProjects: addedUser?.[0]?.assignedProjects,
            add: false
        }]

        setAddedUsers(prevHoliday => [...prevHoliday, ...newData]);
    };

    const handleSaveData = async (e) => {

        try {
            if (selectItem?.project) {
                const { data } = await axios.post(
                    `https://dev.timetraker.com/backend/api/update-user-project-by-project/?companyID=${companyID}`,
                    { users: addedUsers, projectID: selectItem.project._id },
                    config
                );
                if (data) {
                    handleClose()
                    successNotify("Project has been updated!!")
                    setAddedUsers([])
                    setAllUsers([])
                    setRemaningUsers([])
                    getAllProject()
                }
            } else {
                const { data } = await axios.post(
                    `https://dev.timetraker.com/backend/api/update-user-task-by-task/?companyID=${companyID}`,
                    { users: addedUsers, taskID: selectItem.task._id },
                    config
                );
                if (data) {
                    handleClose()
                    successNotify("Task has been updated!!")
                    setAddedUsers([])
                    setAllUsers([])
                    setRemaningUsers([])
                    getAllProject()
                }
            }
        } catch (error) {
            console.log("error from get user timesheet api ", error);
            errorNotify("Something went wrong!!")
        }
    }

    useEffect(() => {

        const userID = addedUsers.map(u => {
            if (u?.add !== false) {
                return u?._id
            }
        });
        const filteredUsers = allUsers.filter(u => !userID.includes(u._id));
        setRemaningUsers(filteredUsers);

    }, [allUsers, addedUsers])




    const errorNotify = (message) => {
        toast.error(message, {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };

    const successNotify = (message) => {
        toast.success(message, {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };

    return (

        <div
            style={{
                minWidth: "886px",
                margin: "0px",
                padding: "20px",
                borderRadius: "10px",
                minHeight: "500px",
                background: "white",
                boxShadow: "none"

            }}
            className="adduser">
            < ToastContainer />
            <div style={{ paddingTop: "30px" }} className="adduser-header">
                <button style={{ width: "182px" }} className="btn1">Assign Info</button>
            </div>

            <div style={{ padding: "20px" }}>
                <div className="user-tab-policy" >
                    <p>User Name</p>
                    {selectItem?.project ?
                        <p>Project Name <span style={{ marginLeft: "42px" }}>Delete</span></p>
                        :
                        <p>Task Name <span style={{ marginLeft: "42px" }}>Delete</span></p>
                    }
                </div>
                {addedUsers?.map((item, index) => {
                    return (
                        <div style={item?.add === false ? { display: "none" } : null} className="user-tab-policy" key={index}>
                            <p> {item?.name}  </p>
                            <p> {selectItem?.project?.project}
                                <i onClick={() => removeHolidayByName(item?._id)}
                                    style={{ color: 'red', fontSize: '20px', cursor: 'pointer', marginLeft: "110px" }}
                                    className="fa-sharp fa-solid fa-trash userIconsHover"
                                ></i>
                            </p>

                        </div>
                    )
                })}

                <div style={{ margin: "20px 70px" }} className="role-border mb-5">
                    <label className="lable_bold" htmlFor="">
                        User
                    </label>
                    <Form.Select
                        required
                        onChange={handleAddUser}
                        autocomplete="off"
                        name="user"
                        className="role"
                        aria-label="Default select example"
                        value={selectdUser}
                    >
                        <option value="">
                            select
                        </option>
                        {remaningUsers?.map((item, index) => {
                            return (
                                <option key={index} value={item?._id}>
                                    {item?.name}
                                </option>
                            )
                        })}
                    </Form.Select>


                </div>
                <div className="adduserBtn ">
                    <button onClick={handleSaveData} className="btn5">
                        Save
                    </button>
                </div>
            </div>

        </div>

    );
};

export default AssignEdit;
