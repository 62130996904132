import React, { useContext } from "react";
import "../AddUser.css";
import { Col, Form, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import signupImg1 from "../../../img/Vector.png";
import signupImg2 from "../../../img/user2.png";
import { useEffect, useState } from "react";
import AllUsersTable from "../../../components/AllUsersTable.js";
import axios from "axios";
import AdminLayout from "../../../hoc/AdminLayout";
import { MyContext } from "../../../context/MyProvider";
import { ToastContainer, toast } from 'react-toastify';
import ChatAfterLogin from "../../../components/chat/ChatAfterLogin";
import ProfileComponent from "../../../components/ProfileComponent";

import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import PolicyTable from "../../../components/PolicyTable.js"
import Checkbox from "@mui/material/Checkbox";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { MenuProps, useStyles, options } from "./utils";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;


function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}


const TimeOffPolicy = () => {
    const navigate = useNavigate();
    const { sideBarStatus, setSideBarStatus } = useContext(MyContext);

    const handleClick = () => {
        if (sideBarStatus == true) {
            setSideBarStatus(false);
        } else {
            setSideBarStatus(true);
        }
    };

    const screenWidth = window.innerWidth;
    if (screenWidth >= 840) {
        var forPhoneScreenNoDisplay = true;
    } else {
        var forPhoneScreenNoDisplay = false;
    }



    const adminloginInfo = JSON.parse(localStorage.getItem("adminLoginInfo"));
    const userLoginInfo = JSON.parse(localStorage.getItem("userLoginInfo"));


    if (adminloginInfo) {
        var companyID = adminloginInfo?._id;
        var token = adminloginInfo?.token
    } else if (userLoginInfo) {
        var companyID = userLoginInfo?.companyID;
        var token = userLoginInfo?.token
    }


    const [allemployee, setAllemployee] = useState([]);

    const classes = useStyles();
    const [selected, setSelected] = useState([]);
    const isAllSelected =
        allemployee.length > 0 && selected.length === allemployee.length;



    const [formData, setFormData] = useState({
        companyID: companyID,
    });
    const [policyInfo, setPolicyInfo] = useState([])
    const [newData, setNewData] = useState()


    // config

    const config = {
        headers: {
            Accept: "application/json",
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`
        },
    };

    const handleDataFromChild = (data) => {
        // Do something with the data in the parent component
        setNewData(data);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        if (name === "employee") {
            const data = allemployee.filter((item) => item._id === value)
            setFormData((prevData) => ({
                ...prevData,
                approverName: data?.[0]?.name,
                approverID: data?.[0]?._id,
            }));
        }
    };

    const theme = useTheme();
    const [personName, setPersonName] = React.useState([]);

    const handleChangeEmployee = (event) => {


        const value = event.target.value;
        if (value[value.length - 1] === "all") {
            if (selected.length === allemployee.length) {
                setSelected([]);
            } else {
                const allNames = allemployee.map(emp => emp.name);
                setSelected(allNames);
            }
            return;
        }
        setSelected(value);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {

            const filteredEmployee = []

            selected.map((item) => {
                const filteredData = allemployee.filter((element) => element.name === item)
                if (filteredData.length > 0) {
                    filteredEmployee.push({
                        userName: filteredData[0].name,
                        userID: filteredData[0]._id,
                        hireDate: filteredData[0].hireDate ? filteredData[0].hireDate : "",
                    })
                }
            })

            const { data } = await axios.post(`https://dev.timetraker.com/backend/api/create-policy/?companyID=${companyID}`,
                {
                    companyID: formData.companyID,
                    applicableTo: filteredEmployee,
                    name: formData.name,
                    policyType: formData.policyType,
                    timeOffLimit: formData.timeOffLimit,
                    alocatedTimeOff: {
                        hours: formData.accrueHours,
                        setting: formData.accrualSetting
                    },
                    waitingPeriod: formData.waitingPeriod,
                    negativeBalance: formData.negativeBalance
                },
                config
            )

            if (data.policyExist) {
                errorNotify("This policay aleady exist!!")
            }
            else if (data) {
                document.getElementById('policyForm').reset();
                successNotify("Policy has been created.")
                setPersonName([])
                setNewData(data)
                setFormData({ companyID: companyID })
                setSelected([])
            }

        } catch (error) {
            console.log("error", error)
            errorNotify("Something went wrong!!")
        }

    };

    const getAllUser = async () => {
        try {
            const { data } = await axios.get(
                `https://dev.timetraker.com/backend/api/all-users/?companyID=${companyID}`,
                config
            );
            return data.allUsers;
        } catch (error) {
            console.log('error from get all user api', error);
            return [];
        }
    };

    const getAllManager = async () => {
        try {
            const { data } = await axios.get(
                `https://dev.timetraker.com/backend/api/all-manager/?companyID=${companyID}`,
                config
            );
            return data.allManager;
        } catch (error) {
            console.log('error from get all manager api', error);
            return [];
        }
    };
    const getAllSubAdmin = async () => {
        try {
            const { data } = await axios.get(
                `https://dev.timetraker.com/backend/api/all-sub-admin/?companyID=${companyID}`,
                config
            );
            return data.allSubAdmin;
        } catch (error) {
            console.log("error from get all user api", error);
        }
    };
    const getSuperAdmin = async () => {
        try {
            const { data } = await axios.get(
                `https://dev.timetraker.com/backend/api/super-admin/?companyID=${companyID}`,
                config
            );
            return data.superAdmin;
        } catch (error) {
            console.log("error from get all user api", error);
        }
    };

    const fetchData = async () => {
        const [users, managers, subAdmins, superAdmin] = await Promise.all([getAllUser(), getAllManager(), getAllSubAdmin(), getSuperAdmin()]);
        setAllemployee([...users, ...managers, ...subAdmins, ...superAdmin]);
    };

    const getAllPolicy = async () => {
        try {
            const { data } = await axios.get(
                `https://dev.timetraker.com/backend/api/get-policy/?companyID=${companyID}`,
                config
            );

            if (data) {
                setPolicyInfo(data.allPolicy)
            }
        } catch (error) {
            console.log("error", error);
        }
    };



    useEffect(() => {
        fetchData()
        getAllPolicy()
        if (!forPhoneScreenNoDisplay) {
            setSideBarStatus(false);
        }
    }, []);

    useEffect(() => {
        getAllPolicy()
    }, [newData])


    const successNotify = (message) => {
        toast.success(message, {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };


    const errorNotify = (message) => {
        toast.error(message, {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };


    return (
        <AdminLayout>
            < ToastContainer />
            <div
                className={sideBarStatus ? "content_right_dashboard" : "none"}
                fluid
                style={
                    (sideBarStatus == true) & (forPhoneScreenNoDisplay == false)
                        ? { display: "none" }
                        : { display: "block" }
                }
            >
                <div
                    className="hamburgar"
                    style={sideBarStatus ? { display: "none" } : { display: "block" }}
                >
                    <i onClick={handleClick} className="fas fa-bars"></i>
                </div>
                <Container fluid className="dash3" style={{ padding: "0" }}>
                    {/* Row 1 */}
                    <Row>
                        <Col md={12} className="adduser-container">
                            <div className="adduser-top">
                                <div className="adduser-header1">
                                    <h5
                                        style={
                                            sideBarStatus == true
                                                ? { paddingLeft: "10px", paddingTop: "2px" }
                                                : { paddingLeft: "60px", paddingTop: "4px" }
                                        }
                                    >
                                        Timesheet Settings
                                    </h5>
                                </div>

                                <div className="adduser-header2">
                                    <ProfileComponent />
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row style={{ background: "#F1F1F1" }} className="mt-0">
                        <Col>
                            <div
                                className="time"
                                style={{
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                    marginLeft: "84px"
                                }}
                            >

                                <div className="text-time">
                                    <p
                                        style={{ backgroundColor: "#64E48E", cursor: "pointer", marginBottom: "0px" }}
                                        className="para1"
                                    >
                                        Policy
                                    </p>
                                    <p
                                        style={{ cursor: "pointer", marginBottom: "0px" }}
                                        className="para1"
                                        onClick={() => {
                                            navigate("/holiday");
                                        }}
                                    >
                                        Holiday
                                    </p>
                                    <p
                                        style={{ cursor: "pointer", marginBottom: "0px" }}
                                        className="para1"
                                        onClick={() => {
                                            navigate("/time-settings");
                                        }}
                                    >
                                        Time Settings
                                    </p>

                                </div>
                            </div>
                        </Col>
                    </Row>

                    {/* Row 2 */}
                    <Row style={{ background: "#F1F1F1" }}>
                        <Col md={12}>
                            <div className="adduser">
                                <div className="adduser-header">
                                    <button className="btn1">Policy Info</button>
                                </div>

                                <form
                                    action=""
                                    // className="adduser-form"
                                    id="policyForm"
                                    onSubmit={handleSubmit}
                                >
                                    <div className="adduser-form">
                                        <div className="form1">
                                            <div className="role-border mb-5">
                                                <label className="lable_bold" htmlFor="">
                                                    Policy Name
                                                    <i className="fa-sharp fa-light fa-asterisk"></i>
                                                </label>
                                                <input
                                                    required
                                                    onChange={handleChange}
                                                    type="text"
                                                    name="name"
                                                    id=""
                                                />
                                            </div>
                                            <div className="role-border mb-5">
                                                <label className="lable_bold" htmlFor="">
                                                    Policy Type
                                                    <i className="fa-sharp fa-light fa-asterisk"></i>
                                                </label>
                                                <Form.Select
                                                    required
                                                    onChange={handleChange}
                                                    name="policyType"
                                                    className="role"
                                                    aria-label="Default select example"
                                                >
                                                    <option value="">Select</option>
                                                    <option value="paid">Paid Time off (PTO) </option>
                                                    <option value="unpaid">Unpaid Time</option>
                                                </Form.Select>

                                            </div>
                                            <div className="role-border mb-5">
                                                <label className="lable_bold" htmlFor="">
                                                    Time Off Limit
                                                    <i className="fa-sharp fa-light fa-asterisk"></i>
                                                </label>
                                                <Form.Select
                                                    required
                                                    onChange={handleChange}
                                                    name="timeOffLimit"
                                                    className="role"
                                                    aria-label="Default select example"
                                                >
                                                    <option value="">Select</option>
                                                    <option value={true}>Yes</option>
                                                    <option value={false}>No</option>
                                                </Form.Select>
                                            </div>

                                            <div className="role-border mb-5">
                                                <label className="lable_bold" htmlFor="">
                                                    Waiting Period (Days)
                                                    <i className="fa-sharp fa-light fa-asterisk"></i>
                                                </label>
                                                <input
                                                    required
                                                    onChange={handleChange}
                                                    type="number"
                                                    name="waitingPeriod"

                                                />


                                            </div>
                                        </div>

                                        <div className="form2">
                                            <div className="role-border mb-5">
                                                <label className="lable_bold" htmlFor="">
                                                    Accrual Setting
                                                    {formData.timeOffLimit === "true"?
                                                    <i className="fa-sharp fa-light fa-asterisk"></i>
                                                    : null}
                                                </label>
                                                <Form.Select
                                                    disabled={formData.timeOffLimit === "false" ? true : false}
                                                    required={formData.timeOffLimit === "false" ? false : true}
                                                    onChange={handleChange}
                                                    name="accrualSetting"
                                                    className="role"
                                                    aria-label="Default select example"
                                                    value={formData.accrualSetting}
                                                >
                                                    <option value="">Select</option>
                                                    <option value="yearly">Yearly</option>
                                                    <option value="monthly">Monthly</option>
                                                </Form.Select>
                                            </div>
                                            <div className="role-border mb-5">
                                                <label className="lable_bold" htmlFor="">
                                                    Accrue (Hours)
                                                    {formData.timeOffLimit === "true"?
                                                    <i className="fa-sharp fa-light fa-asterisk"></i>
                                                    : null}
                                                </label>
                                                <input
                                                    disabled={formData.timeOffLimit === "false" ? true : false}
                                                    required={formData.timeOffLimit === "false" ? false : true}
                                                    style={formData.timeOffLimit === "false" ? { background: "#e9ecef" } : null}
                                                    onChange={handleChange}
                                                    type="number"
                                                    name="accrueHours"

                                                    value={formData.accrueHours}
                                                />


                                            </div>

                                            <div className="role-border mb-5">
                                                <label className="lable_bold" htmlFor="">
                                                    Negative Balance
                                                    <i className="fa-sharp fa-light fa-asterisk"></i>
                                                </label>
                                                <Form.Select
                                                    required
                                                    onChange={handleChange}
                                                    name="negativeBalance"
                                                    className="role"
                                                    aria-label="Default select example"
                                                >
                                                    <option value="">Select</option>
                                                    <option value="true">Yes </option>
                                                    <option value="false">No</option>
                                                </Form.Select>

                                            </div>


                                            <div className="role-border mb-4">
                                                <label className="lable_bold" htmlFor="">
                                                    Employee
                                                </label>
                                                <Select


                                                    className="role"
                                                    labelId="mutiple-select-label"
                                                    multiple
                                                    onChange={handleChangeEmployee}
                                                    value={selected}
                                                    renderValue={(selected) => selected.join(", ")}
                                                    MenuProps={MenuProps}
                                                >

                                                    <MenuItem
                                                        value="all"
                                                        classes={{
                                                            root: isAllSelected ? classes.selectedAll : ""
                                                        }}
                                                    >
                                                        <ListItemIcon>
                                                            <Checkbox
                                                                classes={{ indeterminate: classes.indeterminateColor }}
                                                                checked={isAllSelected}
                                                                indeterminate={
                                                                    selected.length > 0 && selected.length < allemployee.length
                                                                }
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            classes={{ primary: classes.selectAllText }}
                                                            primary="Select All"
                                                        />
                                                    </MenuItem>
                                                    {allemployee.map((item, index) => (

                                                        <MenuItem key={item.name} value={item.name}>
                                                            <ListItemIcon>
                                                                <Checkbox checked={selected.indexOf(item.name) > -1} />
                                                            </ListItemIcon>
                                                            <ListItemText primary={item.name} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="adduserBtn ">
                                        <button type="submit" className="btn5">
                                            Save
                                        </button>
                                    </div>

                                </form>
                            </div>
                        </Col>
                    </Row>


                    <Row style={{ background: "#F1F1F1" }}>
                        <Col>
                            <div className="user-timesheet-table-parent">
                                <PolicyTable
                                    policyInfo={policyInfo}
                                    setPolicyInfo={setPolicyInfo}
                                    handleDataFromChild={handleDataFromChild}
                                    allemployee={allemployee}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

        </AdminLayout>
    );
};

export default TimeOffPolicy;
