import React from 'react';
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route } from 'react-router-dom';
import SignupPage from './pages/SignupPage'
import LoginPage from './pages/LoginPage';
import AdminDashboard from './pages/admin/AdminDashboard';
// import UserDashboard from './pages/user/UserDashboard';
import Task from './pages/admin/Task';
import Project from './pages/admin/Project';
// import UserProfile from './pages/user/UserProfile';
import AdminProfile from './pages/admin/AdminProfile';
import AddUser from './pages/admin/AddUser';
import AdminExpense from './pages/admin/AdminExpense';
// import UserExpense from './pages/user/UserExpense';
import AdminDashboardV2 from './pages/admin/AdminDashboardV2';
import AdminWeeklyTimesheet from './pages/admin/AdminWeeklyTimesheet';
// import UserWeeklyTimesheet from './pages/user/UserWeeklyTimesheet';
import TimesheetAprove from './pages/admin/TimesheetAprove';
import Quickbooks from './pages/admin/Quickbooks';
// import TimeSheet from './pages/admin/Test';
import ForgotPassword from './pages/ForgotPassword';
import { MyProvider } from './context/MyProvider';
import Accessibility from './pages/admin/Accessibility';
import TimesheetReport from './pages/admin/TimesheetReport';
import ExpenseReport from './pages/admin/ExpenseReport';
import LandingPage from './pages/LandingPage';
import ExpenseAprove from './pages/admin/ExpenseAprove';
import Timesheet from './pages/webPages/Timesheet';
import Expense from './pages/webPages/Expense';
import SecureData from './pages/webPages/SecureData';
import Reporting from './pages/webPages/Reporting';
import Receipt from './pages/webPages/Receipt';
import Calculator from './pages/webPages/Calculator';
import Mobile from './pages/webPages/Mobile';
import Pricing from './pages/webPages/Pricing';
import CalendarView from './pages/admin/Calendar';
import FeaturesPage from './LandingAllPages/FeaturesPage';
// import Test from './pages/admin/Test';
import DisconnectQuickbooks from './pages/admin/DisconnectQuickbooks';
import DailyTimesheetManual from "./pages/userManual/DailyTimesheetManual"
import TimerManual from "./pages/userManual/TimerManual"
import WeeklyTimesheetManual from "./pages/userManual/WeeklyTimesheetManual"
import CalenderManual from './pages/userManual/CalenderManual';
import DashboardManual from './pages/userManual/DashboardManual';
import ProjectManual from './pages/userManual/ProjectManual';
import AddUserManual from './pages/userManual/AddUserManual';
import ApproveTimesheetManual from './pages/userManual/ApproveTimesheetManual';
import QuickbooksManual from './pages/userManual/QuickbooksManual';
import TaskManual from './pages/userManual/TaskManual';
import ExpenseAproveManual from './pages/userManual/ExpenseAproveManual';
import ExpenseManual from './pages/userManual/ExpenseManual';
import ReportManual from './pages/userManual/ReportManual';
import ExportManual from './pages/userManual/ExportManual';
import ImportManual from './pages/userManual/ImportManual';
import TechnicalDoc from './pages/TechnicalDoc/TechnicalDoc';
import BackendFile from './pages/TechnicalDoc/BackendFile';
import FrontendFile from './pages/TechnicalDoc/FrontendFile'
import ServerFile from './pages/TechnicalDoc/ServerFile';
import LearnMorePage from './pages/admin/LearnMorePage';
import NewLearmMore from './new/NewLearmMore';
import ManageUser from "./pages/admin/ManageUser"
import Team from './pages/admin/Team';
import Assign from './pages/admin/Assign';

import {
  PrivateRouteForAddUser,
  PrivateRouteForMyTimeInAdmin,
  PrivateRouteForMyTimeInUser,
  PrivateRouteForTask,
  PrivateRouteForProject,
  PrivateRouteForUserProfile,
  PrivateRouteForAdminProfile,
  PrivateRouteForUserExpense,
  PrivateRouteForAdminExpense,
  PrivateRouteForDashboard,
  PrivateRouteForTimesheetAprove,
  PrivateRouteForExpenseAprove,
  PrivateRouteForRole,
  PrivateRouteForReports,
  PrivateRouteForSync,
  PrivateAdminChatAccount,
  PrivateAdminDetailsTrackAccount
} from './auth/PrivateRoute';
import AboutPage from './LandingAllPages/AboutPage';
import ContactSection from './LandingAllPages/ContactSection';
import BlogPage from './LandingAllPages/BlogPage';
import PricePage from './LandingAllPages/PricePage';
import FaqPage from './LandingAllPages/FaqPage';
import FeaturedBlogPage1 from './LandingAllPages/FeaturedBlogPage1';
import FeaturedBlogPage2 from './LandingAllPages/FeaturedBlogPage2';
import FeaturedBlogPage3 from './LandingAllPages/FeaturedBlogPage3';
import FeaturedBlogPage4 from './LandingAllPages/FeaturedBlogPage4';
import FeaturedBlogPage5 from './LandingAllPages/FeaturedBlogPage5';
import FeaturedBlogPage6 from './LandingAllPages/FeaturedBlogPage6';
import FeaturedBlogPage7 from './LandingAllPages/FeaturedBlogPage7';
import FeaturedBlogPage8 from './LandingAllPages/FeaturedBlogPage8';
import FeaturedBlogPage9 from './LandingAllPages/FeaturedBlogPage9';
import FeaturedArticlePage1 from './LandingAllPages/FeaturedArticlePage1';
import FeaturedArticlePage2 from './LandingAllPages/FeaturedArticlePage2';
import FeaturedArticlePage3 from './LandingAllPages/FeaturedArticlePage3';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsConditions from './pages/TermsConditions';
import AdminChat from './pages/adminChat/AdminChat';
import AdminChatLogin from "./pages/adminChatLogin/AdminChatLogin"
import NeedAssistance from './pages/webPages/NeedAssistance';
import AdminDetailsTrackLogin from './pages/adminDetailsTrackLogin/AdminDetailsTrackLogin';
import AdminDetailsTrack from './pages/adminDetailsTack/AdminDetailsTrack';
import ChartOfAccount from './pages/admin/ChartOfAccount';
import TimeOffPolicy from './pages/admin/Timeoff/TimeOffPolicy';
import TimeOffReport from './pages/admin/TimeOffReport';

import SetUpAccount from './pages/admin/SetUpAccount';
import WhoIsWroking from './pages/admin/WhoIsWroking';
import Holiday from './pages/admin/Timeoff/Holiday';
import CompanySettings from "./pages/admin/Settings/CompanySettings"
import TimesheetSettings from './pages/admin/Settings/TimesheetSettings';
import ExpenseSettings from './pages/admin/Settings/ExpenseSettings';
import Xero from './pages/admin/Integrations/Xero';
import Bamboohr from './pages/admin/Integrations/Bamboohr';

function App() {


  return (
    <MyProvider>
      <div className="App">


        <Routes>
          <Route path="/docs/user-manual" element={<DailyTimesheetManual />} />
          <Route path="/docs/timer-manual" element={<TimerManual />} />
          <Route path="/docs/weekly-manual" element={<WeeklyTimesheetManual />} />
          <Route path="/docs/calender-manual" element={<CalenderManual />} />
          <Route path="/docs/dashboard-manual" element={<DashboardManual />} />
          <Route path="/docs/project-manual" element={<ProjectManual />} />
          <Route path="/docs/add-user-manual" element={<AddUserManual />} />
          <Route path="/docs/approve-timesheet-manual" element={<ApproveTimesheetManual />} />
          <Route path="/docs/quickbooks-manual" element={<QuickbooksManual />} />
          <Route path="/docs/task-manual" element={<TaskManual />} />
          <Route path="/docs/expense-manual" element={<ExpenseManual />} />
          <Route path="/docs/aprove-expense-manual" element={<ExpenseAproveManual />} />
          <Route path="/docs/report-manual" element={<ReportManual />} />
          <Route path="/docs/export-manual" element={<ExportManual />} />
          <Route path="/docs/import-manual" element={<ImportManual />} />
          <Route path="/docs/tech-manual" element={<TechnicalDoc />} />
          <Route path="/docs/backend-file-stuc" element={<BackendFile />} />
          <Route path="/docs/frontend-file-stuc" element={<FrontendFile />} />
          <Route path="/docs/node-server-file-stuc" element={<ServerFile />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/TermsConditions" element={<TermsConditions />} />
          <Route path="/FeaturedArticlePage1" element={<FeaturedArticlePage1 />} />
          <Route path="/FeaturedArticlePage2" element={<FeaturedArticlePage2 />} />
          <Route path="/FeaturedArticlePage3" element={<FeaturedArticlePage3 />} />
          <Route path="/FeaturedBlogPage1" element={<FeaturedBlogPage1 />} />
          <Route path="/FeaturedBlogPage2" element={<FeaturedBlogPage2 />} />
          <Route path="/FeaturedBlogPage3" element={<FeaturedBlogPage3 />} />
          <Route path="/FeaturedBlogPage4" element={<FeaturedBlogPage4 />} />
          <Route path="/FeaturedBlogPage5" element={<FeaturedBlogPage5 />} />
          <Route path="/FeaturedBlogPage6" element={<FeaturedBlogPage6 />} />
          <Route path="/FeaturedBlogPage7" element={<FeaturedBlogPage7 />} />
          <Route path="/FeaturedBlogPage8" element={<FeaturedBlogPage8 />} />
          <Route path="/FeaturedBlogPage9" element={<FeaturedBlogPage9 />} />
          <Route path="/aboutpage" element={<AboutPage />} />
          <Route path="/features" element={<FeaturesPage />} />
          <Route path="/pricepage" element={<PricePage />} />
          <Route path="/blogpage" element={<BlogPage />} />
          <Route path="/faqpage" element={<FaqPage />} />
          <Route path="/need-assistance" element={<NeedAssistance />} />
          <Route path="/contactpage" element={<ContactSection />} />
          <Route path="/" element={<LandingPage />} />
          <Route path="/web-page-timesheet" element={<Timesheet />} />
          <Route path="/web-page-expense" element={<Expense />} />
          <Route path="/calendar-timesheet" element={<PrivateRouteForMyTimeInAdmin><CalendarView /></PrivateRouteForMyTimeInAdmin>} />
          <Route path="/web-page-secure-data" element={<SecureData />} />
          <Route path="/web-page-reporting" element={<Reporting />} />
          <Route path="/web-page-receipt" element={<Receipt />} />
          <Route path="/web-page-calculator" element={<Calculator />} />
          <Route path="/web-page-mobile" element={<Mobile />} />
          <Route path="/web-page-pricing" element={<Pricing />} />
          <Route path="/Signup" element={<SignupPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/learn-more-QuickBooks" element={<NewLearmMore />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/my-time" element={<AdminDashboard />} />
          {/* <Route path="/user-dashboard" element={<PrivateRouteForMyTimeInUser><UserDashboard /></PrivateRouteForMyTimeInUser>} /> */}
          <Route path="/task" element={<PrivateRouteForTask><Task /></PrivateRouteForTask>} />
          <Route path="/project" element={<PrivateRouteForProject><Project /></PrivateRouteForProject>} />
          <Route path="/assign" element={<PrivateRouteForProject><Assign /></PrivateRouteForProject>} />
          {/* <Route path="/user-profile" element={<PrivateRouteForUserProfile><UserProfile /></PrivateRouteForUserProfile>} /> */}
          <Route path="/profile" element={<PrivateRouteForAdminProfile><AdminProfile /></PrivateRouteForAdminProfile>} />
          <Route path="/add-user" element={<PrivateRouteForAddUser><AddUser /></PrivateRouteForAddUser>} />
          <Route path="/manage-user" element={<PrivateRouteForAddUser><ManageUser /></PrivateRouteForAddUser>} />
          <Route path="/team" element={<PrivateRouteForAddUser><Team/></PrivateRouteForAddUser>} />
          <Route path="/my-expense" element={<PrivateRouteForAdminExpense><AdminExpense /></PrivateRouteForAdminExpense>} />
          {/* <Route path="/user-expense" element={<PrivateRouteForUserExpense><UserExpense /></PrivateRouteForUserExpense>} /> */}
          <Route path="/admin-dashboard-v2" element={<PrivateRouteForDashboard><AdminDashboardV2 /></PrivateRouteForDashboard>} />
          <Route path="/company-settings" element={<PrivateRouteForDashboard><CompanySettings /></PrivateRouteForDashboard>} />
          <Route path="/time-off-policy" element={<PrivateRouteForDashboard>< TimeOffPolicy /></PrivateRouteForDashboard>} />
          <Route path="/holiday" element={<PrivateRouteForDashboard>< Holiday /></PrivateRouteForDashboard>} />
          <Route path="/time-settings" element={<PrivateRouteForDashboard>< TimesheetSettings /></PrivateRouteForDashboard>} />
          <Route path="/expense-settings" element={<PrivateRouteForDashboard>< ExpenseSettings /></PrivateRouteForDashboard>} />
          <Route path="/who-is-working" element={<PrivateRouteForDashboard><WhoIsWroking /></PrivateRouteForDashboard>} />
          <Route path="/weekly-timesheet" element={<PrivateRouteForMyTimeInAdmin><AdminWeeklyTimesheet /></PrivateRouteForMyTimeInAdmin>} />
          {/* <Route path="/user-weekly-timesheet" element={<PrivateRouteForMyTimeInUser>< UserWeeklyTimesheet/></PrivateRouteForMyTimeInUser>} /> */}
          <Route path="/timesheet-aprove" element={<PrivateRouteForTimesheetAprove><TimesheetAprove /></PrivateRouteForTimesheetAprove>} />
          <Route path="/expense-aprove" element={<PrivateRouteForExpenseAprove><ExpenseAprove /></PrivateRouteForExpenseAprove>} />
          {/* <Route path="/accessibility" element={<PrivateRouteForRole><Accessibility /></PrivateRouteForRole>} /> */}
          <Route path="/timesheet-reports" element={<PrivateRouteForReports><TimesheetReport /></PrivateRouteForReports>} />
          <Route path="/expense-reports" element={<PrivateRouteForReports><ExpenseReport /></PrivateRouteForReports>} />
          <Route path="/timeOff-reports" element={<PrivateRouteForReports><TimeOffReport /></PrivateRouteForReports>} />
          <Route path="/quickbooks" element={<PrivateRouteForSync><Quickbooks /></PrivateRouteForSync>} />
          <Route path="/bamboohr" element={<PrivateRouteForSync><Bamboohr /></PrivateRouteForSync>} />
          <Route path="/xero" element={<PrivateRouteForSync><Xero /></PrivateRouteForSync>} />
          <Route path="/chart-of-account" element={<PrivateRouteForSync><ChartOfAccount /></PrivateRouteForSync>} />
          <Route path="/disconnect-quickbooks" element={<DisconnectQuickbooks />} />



          <Route path="/admin-chat-account-login" element={< AdminChatLogin/>} />
          <Route path="/admin-chat-suport" element={<PrivateAdminChatAccount><AdminChat /> </PrivateAdminChatAccount>} />

          
          <Route path="/admin-details-track-account-login" element={< AdminDetailsTrackLogin/>} />
          <Route path="/admin-details-track-page" element={<PrivateAdminDetailsTrackAccount><AdminDetailsTrack /> </PrivateAdminDetailsTrackAccount>} />
          {/* <Route path="/test" element={<Test />} /> */}

          <Route path="/set-up-account" element={<PrivateRouteForDashboard> < SetUpAccount/> </PrivateRouteForDashboard>} />

        </Routes>

      </div>
    </MyProvider>
  );
}

export default App;
