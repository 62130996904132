import React, { useState, useEffect, useContext } from "react";
import { Col, Container, Row, Button, Form } from "react-bootstrap";
import axios from "axios";
import AdminLayout from "../../hoc/AdminLayout";
import { MyContext } from "../../context/MyProvider";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProfileComponent from "../../components/ProfileComponent";
import Checkbox from '@mui/material/Checkbox';
import { blue } from '@mui/material/colors';
import "./SetUpAccount.css"
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import cardImg4 from "../../img/11019415_wallet_accounting_expense_income_dollar_icon 1.svg"
import quickbooksimg from "../../img/quickbooks-brand.png"
import bambooimg from "../../img/08682353-77bf-4138-8019-e218543808fc.png"
import xeroimg from "../../img/xeroLogo.png"
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useCallback } from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { Dialog } from "@mui/material";

const containerStyle = {
    width: '100%',
    height: '400px'
};




const SetUpAccount = () => {
    const navigate = useNavigate();

    const { sideBarStatus, setSideBarStatus } = useContext(MyContext);

    const handleClick = () => {
        if (sideBarStatus == true) {
            setSideBarStatus(false);
        } else {
            setSideBarStatus(true);
        }
    };

    const screenWidth = window.innerWidth;
    if (screenWidth >= 840) {
        var forPhoneScreenNoDisplay = true;
    } else {
        var forPhoneScreenNoDisplay = false;
    }

    const adminloginInfo = JSON.parse(localStorage.getItem("adminLoginInfo"));
    const userLoginInfo = JSON.parse(localStorage.getItem("userLoginInfo"));
    if (adminloginInfo) {
        var companyID = adminloginInfo?._id;
        var token = adminloginInfo?.token
        var name = adminloginInfo?.name
    }
    if (userLoginInfo) {
        var companyID = userLoginInfo?.companyID;
        var token = userLoginInfo?.token
        var name = userLoginInfo?.name
    }

    // config

    const config = {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`
        },
    };

    const [accessibility, setAcceessibility] = useState({
        myTimesheets: adminloginInfo.accessibility.myTimesheets,
        myExpenses: adminloginInfo.accessibility.myExpenses,
        expenses: adminloginInfo.accessibility.expenses,
        timesheets: true,
        sync: adminloginInfo.accessibility.sync,
        bamboohr: adminloginInfo.accessibility?.bamboohr ? adminloginInfo.accessibility?.bamboohr : false,
        xero: adminloginInfo.accessibility?.xero ? adminloginInfo.accessibility?.xero : false,
        download: adminloginInfo.accessibility.download,
        demoData: false,
        TimesheetLocationTrack: adminloginInfo.accessibility.TimesheetLocationTrack,
        ExpenseLocationTrack: adminloginInfo.accessibility.ExpenseLocationTrack
    });
    const [marker, setMarker] = useState(null);
    const [geofencing, setGeofencing] = useState(false)
    const [checkGeofencing, setCheckGeofencing] = useState(
        adminloginInfo?.geofencing?.latitude && adminloginInfo?.geofencing?.longitude ? true : false
    )
    const [change, setChange] = useState(false)


    const setup = async () => {
        try {
            const { data } = await axios.post("https://dev.timetraker.com/backend/api/update-accessibility-for-company",
                {
                    accessibility: accessibility,
                    companyID: companyID,
                    userName: name,
                    geofencing: checkGeofencing ? marker : null
                },
                config
            )
            if (data) {
                adminloginInfo.accessibility.myTimesheets = accessibility.myTimesheets
                adminloginInfo.accessibility.myExpenses = accessibility.myExpenses
                adminloginInfo.accessibility.expenses = accessibility.expenses
                adminloginInfo.accessibility.timesheets = accessibility.timesheets
                adminloginInfo.accessibility.sync = accessibility.sync
                adminloginInfo.accessibility.bamboohr = accessibility.bamboohr
                adminloginInfo.accessibility.xero = accessibility.xero
                adminloginInfo.accessibility.download = accessibility.download
                adminloginInfo.accessibility.TimesheetLocationTrack = accessibility.TimesheetLocationTrack
                adminloginInfo.accessibility.ExpenseLocationTrack = accessibility.ExpenseLocationTrack
                adminloginInfo.geofencing.latitude = marker?.lat
                adminloginInfo.geofencing.longitude = marker?.lng

                localStorage.setItem('adminLoginInfo', JSON.stringify(adminloginInfo));
                navigate("/admin-dashboard-v2")
            }
        } catch (error) {
            console.log("error", error)
            alert("Something went wrong!!")
        }
    }



    useEffect(() => {
        if (!forPhoneScreenNoDisplay) {
            setSideBarStatus(false);
        }
    }, []);

    const [open, setOpen] = React.useState(false);


    const handleClickOpen = () => {
        setGeofencing(true)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyBQwpp2897AHVB0tI-sE5LRGfDwE_iHnGQ'
    });


    const onClick = useCallback((event) => {
        setMarker({
            lat: event.latLng.lat(),
            lng: event.latLng.lng()
        });
        handleClose()

    }, []);

    const [center, setCenter] = useState({ lat: -3.745, lng: -38.523 });
    const [error, setError] = useState(null);


    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setCenter({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    });
                },
                (error) => {
                    setError(error.message);
                },
                {
                    enableHighAccuracy: true,
                    timeout: 5000,
                    maximumAge: 0,
                }
            );
        } else {
            setError('Geolocation is not supported by this browser.');
        }
    };

    useEffect(() => {
        if (geofencing) {
            getLocation();
        }
    }, [geofencing]);

    useEffect(() => {
        if (checkGeofencing && change) {
            setAcceessibility((previousData) => ({
                ...previousData,
                TimesheetLocationTrack: true,
                ExpenseLocationTrack: true
            }))
            handleClickOpen()
        }
    }, [checkGeofencing]);

    const coloredMarkerIcon = (color) => ({
        url: `http://maps.google.com/mapfiles/ms/icons/${color}-dot.png`
    });


    return (
        <AdminLayout>
            <ToastContainer />
            <div
                className={sideBarStatus ? "content_right_dashboard_2" : "none"}
                fluid
                style={
                    (sideBarStatus == true) & (forPhoneScreenNoDisplay == false)
                        ? { display: "none" }
                        : { display: "block", minHeight: "100vh", background: "#f1f1f1" }
                }
            >
                <Container fluid className="dash3" style={{ padding: "0" }}>
                    {/* Row 1 */}
                    <Row>
                        <Col className="task-container">
                            <div
                                className="hamburgar"
                                style={
                                    sideBarStatus ? { display: "none" } : { display: "block" }
                                }
                            >
                                <i onClick={handleClick} className="fas fa-bars"></i>
                            </div>
                            <div className="task-top">
                                <div className="task-header1">
                                    <h5
                                        style={
                                            sideBarStatus == true
                                                ? { paddingLeft: "10px", paddingTop: "2px" }
                                                : { paddingLeft: "60px", paddingTop: "4px" }
                                        }
                                    >
                                        Account Set Up
                                    </h5>
                                </div>

                                <div className="profile-header2">

                                    <ProfileComponent />
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row className="step_wizard_body">
                        <h2>What do you want to do with TimeTraker?</h2>
                        <p>Choose from the below options. We will customize your account accordingly.</p>

                        <div className="cards">

                            <div className="cards2">

                                <div className="step_card">
                                    <AccessTimeFilledIcon style={{ fontSize: "40px", color: "#04542c" }} />
                                    <p>Track Time</p>
                                    <Checkbox
                                        defaultChecked={accessibility.myTimesheets ? true : false}
                                        onChange={() => {
                                            setAcceessibility((previousData) => ({
                                                ...previousData,
                                                timesheets: !accessibility.timesheets,
                                                myTimesheets: !accessibility.myTimesheets
                                            }))
                                        }}
                                        sx={{
                                            color: blue[800],
                                            '&.Mui-checked': {
                                                color: blue[600],
                                            },
                                        }} />
                                </div>

                                <div className="step_card">
                                    <img style={{ width: "40px" }} src={cardImg4} alt="" />
                                    <p>Track expenses</p>
                                    <Checkbox
                                        defaultChecked={accessibility.myExpenses ? true : false}
                                        onChange={() => {
                                            setAcceessibility((previousData) => ({
                                                ...previousData,
                                                myExpenses: !accessibility.myExpenses,
                                                expenses: !accessibility.expenses
                                            }))
                                        }}
                                        sx={{
                                            color: blue[800],
                                            '&.Mui-checked': {
                                                color: blue[600],
                                            },
                                        }} />
                                </div>

                                <div className="step_card">
                                    <i style={{ fontSize: "40px", color: "#04542c" }} class="fa-solid fa-database"></i>
                                    <p>Add demo data</p>
                                    <Checkbox
                                        defaultChecked={accessibility.demoData ? true : false}
                                        onChange={() => {
                                            setAcceessibility((previousData) => ({
                                                ...previousData,
                                                demoData: !accessibility.demoData,
                                            }))
                                        }}
                                        sx={{
                                            color: blue[800],
                                            '&.Mui-checked': {
                                                color: blue[600],
                                            },
                                        }} />
                                </div>




                                <div className="step_card">
                                    <LocationOnIcon style={{ fontSize: "40px", color: "#04542c" }} />
                                    <p style={{ marginTop: "5px", textAlign: "center" }}>Timesheet Geo Location</p>
                                    <Checkbox
                                        defaultChecked={accessibility.TimesheetLocationTrack ? true : false}
                                        checked={accessibility.TimesheetLocationTrack ? true : false}
                                        onChange={() => {
                                            setAcceessibility((previousData) => ({
                                                ...previousData,
                                                TimesheetLocationTrack: !accessibility.TimesheetLocationTrack
                                            }))
                                        }}
                                        sx={{
                                            color: blue[800],
                                            '&.Mui-checked': {
                                                color: blue[600],
                                            },
                                        }} />
                                </div>

                                <div className="step_card">
                                    <LocationOnIcon style={{ fontSize: "40px", color: "#04542c" }} />
                                    <p style={{ marginTop: "5px", textAlign: "center" }}>Expense Geo Location</p>
                                    <Checkbox
                                        defaultChecked={accessibility.ExpenseLocationTrack ? true : false}
                                        checked={accessibility.ExpenseLocationTrack ? true : false}
                                        onChange={() => {
                                            setAcceessibility((previousData) => ({
                                                ...previousData,
                                                ExpenseLocationTrack: !accessibility.ExpenseLocationTrack
                                            }))
                                        }}
                                        sx={{
                                            color: blue[800],
                                            '&.Mui-checked': {
                                                color: blue[600],
                                            },
                                        }} />
                                </div>

                                <div className="step_card">
                                    <LocationOnIcon style={{ fontSize: "40px", color: "#04542c" }} />
                                    <p style={{ marginTop: "5px", textAlign: "center" }}>Geo fencing</p>
                                    <Checkbox
                                        defaultChecked={checkGeofencing ? true : false}
                                        onChange={() => {
                                            setCheckGeofencing(!checkGeofencing)
                                            setChange(true)
                                        }}
                                        sx={{
                                            color: blue[800],
                                            '&.Mui-checked': {
                                                color: blue[600],
                                            },
                                        }} />
                                </div>

                            </div>

                            <h2 style={{ marginTop: "30px" }}> Integrations</h2>
                            <p>Could you please share the options you'd like to consider for integration?</p>

                            <div className="cards2">

                                <div className="step_card">
                                    <img style={{ width: "125px" }} src={quickbooksimg} alt="" />
                                    <p style={{ marginTop: "5px" }}>QuickBooks</p>
                                    <Checkbox
                                        defaultChecked={accessibility.sync ? true : false}
                                        onChange={() => {
                                            setAcceessibility((previousData) => ({
                                                ...previousData,
                                                sync: !accessibility.sync,
                                                download: !accessibility.download,
                                            }))
                                        }}
                                        sx={{
                                            color: blue[800],
                                            '&.Mui-checked': {
                                                color: blue[600],
                                            },
                                        }} />
                                </div>


                                <div className="step_card">
                                    <img style={{ width: "86px", margin: "7px" }} src={bambooimg} alt="" />
                                    <p style={{ marginTop: "5px" }}>BambooHR</p>
                                    <Checkbox
                                        defaultChecked={accessibility.bamboohr ? true : false}
                                        onChange={() => {
                                            setAcceessibility((previousData) => ({
                                                ...previousData,
                                                bamboohr: !accessibility.bamboohr
                                            }))
                                        }}
                                        sx={{
                                            color: blue[800],
                                            '&.Mui-checked': {
                                                color: blue[600],
                                            },
                                        }} />
                                </div>

                                <div className="step_card">
                                    <img style={{ width: "60px" }} src={xeroimg} alt="" />
                                    <p style={{ marginTop: "5px" }}>Xero</p>
                                    <Checkbox
                                        defaultChecked={accessibility.xero ? true : false}
                                        onChange={() => {
                                            setAcceessibility((previousData) => ({
                                                ...previousData,
                                                xero: !accessibility.xero
                                            }))
                                        }}
                                        sx={{
                                            color: blue[800],
                                            '&.Mui-checked': {
                                                color: blue[600],
                                            },
                                        }} />
                                </div>
                            </div>

                            <div className="button">
                                <button onClick={setup}>Continue</button>
                            </div>


                        </div>
                    </Row>

                </Container>
            </div>

            <Dialog
                open={open}
                maxWidth="sm" fullWidth
                onClose={handleClose}
            >
                <div>
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={10}
                        onClick={onClick}
                    >
                        {marker && <Marker position={marker} icon={coloredMarkerIcon('red')} />}
                        {center && <Marker position={{
                            lat: center.lat,
                            lng: center.lng
                        }}
                            icon={coloredMarkerIcon('blue')}
                        />}

                    </GoogleMap>

                </div>

            </Dialog>
        </AdminLayout>
    )
}

export default SetUpAccount
